import sAction from 'sAction';
import {Map} from 'immutable';

export default class rest {
    constructor(parent) {
        this.url = parent.param.server;
        this.token = null;
        this.parent = parent;
        this.xhr = null;
        this.controller = null;
    }

    /**
     * send a POST request to the rest api
     * @param {string} action relative url path
     * @param {object} data data to send as json to rest
     * @param {func} complete callback after response is received
     * @param {bool} cancelPreviousRequest if previous request should be cancelled
     * @deprecated use fetchData instead
     */
    post(action, data, complete, cancelPreviousRequest = true) {
        this.call(
            action,
            'POST',
            data,
            complete,
            cancelPreviousRequest,
        );
    }
    /**
     * send a GET request to the rest api
     * @param {string} action relative url path
     * @param {func} complete callback after response is received
     * @param {bool} cancelPreviousRequest if previous request should be cancelled
     * @deprecated use fetchData instead
     */
    get(action, complete, cancelPreviousRequest = true) {
        this.call(
            action,
            'GET',
            null,
            complete,
            cancelPreviousRequest,
        );
    }

    /**
     * send a DELETE request to the rest api
     * @param {string} action relative url path
     * @param {object} data data to send as json to rest
     * @param {func} complete callback after response is received
     * @deprecated use fetchData instead
     */
    delete(action, data, complete) {
        this.call(action, 'DELETE', data, complete);
    }

    /**
     * call rest api with given request
     * @param {string} action relative url path
     * @param {string} method HTTP request method
     * @param {object} data data to send as json to rest
     * @param {func} complete callback after response is received
     * @param {bool} cancelPreviousRequest if previous request should be cancelled
     * @deprecated use fetchData instead
     */
    call(action, method, data, complete, cancelPreviousRequest) {
        const self = this;
        const sId = this.getAuth();
        if (this.xhr !== null && cancelPreviousRequest === true) {
            this.controller.abort();
            this.controller = null;
        }
        this.controller = new AbortController();

        if (this.parent?.param?.xdebugEnabled) {
            action += (action?.includes('?') ? '&' : '?') + 'XDEBUG_SESSION_START=1';
        }

        const headersData = {
            'content-type': 'application/json',
            // 'sId': auth.sID
        };

        // adds check sid and other parameters from auth to headers
        Object.entries(sId).forEach(([key, value]) => {
            if (key !== 'sID' && !self.parent.param.is_portal) {
                return;
            }
            headersData[key] = value;
        });

        const settings = {
            method: method,
            contentType: 'application/json',
            headers: new Headers({
                'content-type': 'application/json',
                'sId': sId.sID,
                'device': self.parent.deviceType,
            }),
            signal: this.controller.signal,
        };
        if (['POST', 'PUT', 'DELETE', 'PATCH'].includes(method)) {
            settings.body = JSON.stringify(data);
        }

        this.xhr = fetch( self.url + '/' + action, settings)
            .then((response) => this.checkResponse(response, action, self)).then((data) => {
                if (typeof data === 'object' && data.status === 'error') {
                    if (data.reason !== undefined) {
                        self.parent.dsClear();
                        if (self.parent.dataGet('rightPanel/show') === true) {
                            self.parent.dsAdd('set', 'rightPanel/content', 'error');
                            self.parent.dsAdd('set', 'conf/load', false);
                            self.parent.dsAdd('set', 'rightPanel/data', {
                                state: false,
                                type: data.reason,
                            });
                        } else {
                            self.parent.dsAdd('set', 'conf/view', 'error');
                            self.parent.dsAdd('set', 'conf/load', false);
                            self.parent.dsAdd('set', 'view', {
                                state: false,
                                type: data.reason,
                            });
                        }

                        self.parent.dsProcess();
                    } else {
                        self.parent.unLoad();
                        self.parent.error(self.parent.translate(data.msg || data.message));
                    }
                }
                complete(data);
                if (typeof data === 'object' && data.status === 'warning') {
                    setTimeout(function() {
                        self.parent.error(self.parent.translate(data.msg || data.message));
                    }, 2500);
                }
            }).catch((responseError) => {
                console.error(responseError);
            });
    }

    /**
     * checks HTTP response for specific errors and handles them
     * @param {Response} response HTTP response
     * @param {string} action relative url path
     * @param {rest} self self reference
     * @returns response JSON
     */
    checkResponse(response, action, self) {
        if (
            ((response.status === 401 || response.status === 0) && response.statusText !== 'abort') &&
            action !== 'getNotifyCount' && action !== 'recalcTopRecent'
        ) {
            if (!window.location.hash.startsWith('#login')) {
                if (response.status === 401) {
                    clearInterval(this.parent.notificationInterval);

                    self.parent.routeLogin();
                } else {
                    self.parent.error(self.parent.translate('LBL_SERVER_ERROR'));
                    self.parent.unLoad();
                }
            } else {
                clearInterval(this.parent.notificationInterval);

                self.parent.routeLogin();
            }
        }

        return response.json();
    }

    /**
     * @return {{sID: string}}
     */
    getAuth() {
        const token = this.getCookie('sID');
        const auth = {
            sID: token,
        };

        if (this.parent.deviceType !== undefined) {
            auth['device'] = this.parent.deviceType;
        }

        return auth;
    }

    /**
     * adds a cookie
     * @param {string} cname cookie name
     * @param {*} cvalue cookie value
     * @param {string} exdays days the cookie is valid for
     */
    setCookie(cname, cvalue, exdays) {
        const d = new Date();
        d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
        const expires = 'expires=' + d.toUTCString();
        document.cookie = cname + '=' + cvalue + '; ' + expires;
    }

    /**
     * get a cookie value
     * @param {string} cname cookie name
     * @returns {string|null} cookie value, null if not found
     */
    getCookie(cname) {
        const name = cname + '=';
        const ca = document.cookie.split(';');
        for (let i = 0; i < ca.length; i++) {
            let c = ca[i];
            while (c.charAt(0) === ' ') c = c.substring(1);
            if (c.indexOf(name) === 0) return c.substring(name.length, c.length);
        }

        return null;
    }

    /**
     * Metoda pocita s formatem dat v coripo standardu, jinak nebude fungovat spravne.
     * @param {string} action
     * @param {string} method
     * @param {null|any} data
     * @param {boolean} cancelPreviousRequest
     * @return {Promise<any>}
     */
    async fetchData(action, method, data = null, cancelPreviousRequest = true) {
        const self = this;
        const sId = this.getAuth();
        let url = self.url + '/' + action;
        if (this.parent?.param?.xdebugEnabled) {
            url += (url?.includes('?') ? '&' : '?') + 'XDEBUG_SESSION_START=1';
        }

        if (this.controller && cancelPreviousRequest) {
            this.controller.abort();
        }

        this.controller = new AbortController();

        const fetchParams = {
            method: method,
            contentType: 'application/json',
            signal: this.controller.signal,
            headers: new Headers({
                'content-type': 'application/json',
                'sId': sId.sID,
                'device': self.parent.deviceType,
            }),
        };

        if (['POST', 'PUT', 'PATCH'].includes(method) && data) {
            fetchParams.body = JSON.stringify(data);
        } else if (data && typeof data === 'object' && data !== {}) {
            // encode data to url query string for methods that don't support body
            // immutable Map used so object can be treated as associative array
            url += (url?.includes('?') ? '&' : '?') + (new Map(data)).map((value, param) => {
                // if value is array, add multiple params with same name and [] at the end
                if (Array.isArray(value)) {
                    return value.map((value) => {
                        return encodeURIComponent(param) + '[]=' + encodeURIComponent(value);
                    }).join('&');
                }

                return encodeURIComponent(param) + '=' + encodeURIComponent(value);
            }).join('&');
        } else if (data && data !== {}) {
            console.error(`Unsupported data of type ${typeof data} for method ${method}`);
        }

        const fetchedData = await fetch(url, fetchParams).then((response) => response.json()).then((result) => {
            return result;
        }).catch((responseError) => {
            switch (responseError.name) {
                case 'AbortError':
                    console.warn(`Request ${action} canceled`);
                    break;
                default:
                    this.parent.unLoad();
                    console.error('Error', responseError);
                    console.warn('You propably have error in your BE method returned data is not valid JSON');
            }
        });

        if (!fetchedData?.status) {
            throw fetchedData?.errorMessage;
        }

        return fetchedData.message;
    }

    /**
     *
     * @returns {Promise<{Authorization: string}>}
     */
    async getAuthMS() {
        let token = sAction.dataGet('conf/user/microservice_access_token');
        let expiresAt = sAction.dataGet('conf/user/microservice_access_token_expires_at');
        // let allowedScopes = sAction.dataGet('conf/user/microservice_access_token_allowed_scopes');
        const now = new Date();
        const expires = new Date(expiresAt);
        if (!token || expires < now) {
            const data = await this.fetchData('CoripoMicroservices/getAccessToken', 'GET');
            token = data?.data?.accessToken ?? '';
            expiresAt = data?.data?.accessToken ?? '';
            sAction.dataSet('conf/user/microservice_access_token', token);
            sAction.dataSet('conf/user/microservice_access_token_expires_at', expiresAt);
        }
        return {
            'Authorization': `Bearer ${token}`,
        };
    }

    /**
     *
     * @param {string} action
     * @param {string} method
     * @param {{},null} data
     * @returns {Promise<Response|{data: {}}>}
     */
    async fetchMS(action, method, data = null) {
        const msURL = sAction.dataGet('conf/user/microservice_url');
        if (!msURL) {
            sAction.error('Missing microservice url');
            return {data: {}};
        }
        let getParams = '';

        const headers = {
            ...await this.getAuthMS(),
            'Content-Type': 'application/json',
        };

        const config = {
            method,
            contentType: 'application/json',
            headers: headers,
            // signal: todo
        };

        if (['GET'].includes(method)) {
            getParams += (getParams?.includes('?') ? '&' : '?') + new URLSearchParams(data);
        } else {
            config.body = JSON.stringify(data);
        }

        const url = msURL + '/' + action + getParams;

        return await fetch(url, config)
            .then((responseRaw) => {
                const responseJson = responseRaw.json();
                if (responseRaw.ok) {
                    return responseJson;
                }
                let trans = '';
                switch (responseRaw.status) {
                    case 403:
                    case 401:
                        trans = sAction.translate('LBL_MS_UNAUTHORIZED');
                        sAction.error(trans);
                        console.error(trans, responseJson);
                        return responseJson;
                    default:
                        trans = sAction.translate('LBL_MS_UNKNOWN_ERROR');
                        sAction.error(trans);
                        console.error(trans, responseJson);
                        return responseJson;
                }
            }).then((response) => {
                return response;
            });
    }
}
