/**
 *
 * @param {*} data
 */
export default function detailTabNext(data) {
    let newField;

    if (!data.rowWay) {
        data.rowWay = `${data.prefix}/tabs/${this.dataGet(`${data.prefix}/activeTab`)}/panels/0/rows/0`;
        newField = this.dataGet(`${data.rowWay}/fields/0/name`);
    }

    const rowWay = data.rowWay;
    const rowWayExplode = rowWay.split('/');

    rowWayExplode.splice(rowWayExplode.length - 3, 3);

    const panelsData = this.dataGet(rowWayExplode.join('/'));

    const fieldsList = getFieldsList(panelsData);

    if (!newField) {
        newField = getNextField(fieldsList, data.name, data, this);
    }

    if (newField != null) {
        if (this.detailTempData[data.prefix] === undefined) {
            this.detailTempData[data.prefix] = {
                activeField: newField,
            };
        } else {
            this.detailTempData[data.prefix]['activeField'] = newField;
        }
        this.dsClear();
        if (data.error !== true) {
            this.dsAdd('set', data.prefix + '/fields/' + data.name + '/actEdit', false);
            this.dsAdd('set', data.prefix + '/fields/' + newField + '/actEdit', true);
        }

        const newType = this.dataGet(data.prefix + '/fields/' + newField + '/def/type');
        if (newType === 'enum' || newType === 'currency_id' || newType === 'multienum') {
            this.dsAdd('set', data.prefix + '/fields/' + newField + '/def/forceOpen', true);
        }
        this.dsProcess();
    }
}

/**
 *
 * @param {*} fieldsList
 * @param {*} name
 * @param {*} data
 * @param {*} sAction
 * @returns
 */
function getNextField(fieldsList, name, data, sAction) {
    const currentIndex = fieldsList.indexOf(name);

    let nextIndex = data.shift ? currentIndex - 1 : currentIndex + 1;

    if (nextIndex === fieldsList.length) {
        nextIndex = 0;
    } else if (nextIndex === -1) {
        nextIndex = fieldsList.length - 1;
    }

    const newField = fieldsList[nextIndex];

    if (!newField || newField === 'empty_row') {
        return getNextField(fieldsList, newField, data, sAction);
    } else {
        const displayIfNew = sAction.dataGet(`${data.prefix}/fields/${newField}/def/displayIfNew`);
        const id = sAction.dataGet(`${data.prefix}/id`);
        const readonly = sAction.dataGet(`${data.prefix}/fields/${newField}/def/readonly`);
        const type = sAction.dataGet(`${data.prefix}/fields/${newField}/def/type`);
        const skippedTypes = ['bool', 'image', 'radioenum', 'file', 'button'];
        if (readonly === true || skippedTypes.indexOf(type) !== -1 || (displayIfNew === false && id === null)) {
            return getNextField(fieldsList, newField, data, sAction);
        }

        return newField;
    }
}

/**
 *
 * @param {Array} panelsData
 * @returns {Array}
 */
function getFieldsList(panelsData) {
    let rIndex = 0;
    const matrix = [];
    const addressOrder = [
        'street',
        'city',
        'postalcode',
        'state',
        'kraj',
        'country',
    ];

    panelsData.forEach((panel) => {
        panel.get('rows').forEach((row) => {
            matrix[rIndex] = [];

            row.fields.forEach((field) => {
                let tempIndex = 0;
                const splitName = field.name?.split('_');
                if (splitName.length === 3 && splitName.indexOf('address') !== -1) {
                    addressOrder.forEach((addressField, addressIndex) => {
                        splitName[2] = addressField;
                        tempIndex = addressIndex + rIndex;

                        matrix[tempIndex] = matrix[tempIndex] || [];
                        matrix[tempIndex].push(splitName.join('_'));
                    });

                    if (matrix[tempIndex].length === parseInt(panel.get('columns'))) {
                        rIndex += tempIndex;
                    }
                } else {
                    matrix[rIndex].push(field.name);
                }
            });

            rIndex++;
        });
    });

    const fieldsList = [];
    matrix.forEach((row) => {
        row.forEach((field) => {
            fieldsList.push(field);
        });
    });

    return fieldsList;
}
