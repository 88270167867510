import PropTypes from 'prop-types';
import React from 'react';
import PureComponent from '../pure';
import InputText from '../formElements/InputText';
import RecentRecordsManager from '../records/recentRecords';

import Breadcrumb from './breadcrumb';
import NotificationCenter from '../notification/NotificationCenter';
import sAction from 'sAction';
import TooltipWrapper from 'ROOT/src/components/Tooltip/TooltipWrapper';

class Header extends PureComponent {
    constructor() {
        super();
        this.searchInput = React.createRef();
    }

    /**
   *
   */
    handleSearch() {
        const val = this.searchInput.current.value;
        sAction.href('#globalSearch/' + encodeURI(val));
    // sAction.route();
    }

    /**
   *
   * @param {Object} ev
   */
    searchKeyUp(ev) {
        // eslint-disable-next-line eqeqeq
        if (ev.keyCode == 13) {
            this.handleSearch();
        }
    }
    /**
   *
   */
    alertMessageClick() {
        const alertMessage = this.props.alertMessage;
        if (sAction[alertMessage.get('buttonAction')] != null) {
            sAction[alertMessage.get('buttonAction')]();
        } else {
            console.warn(
                'Akce ' + alertMessage.get('buttonAction') + ' není definována',
            );
        }
    }

    render() {
        const user = this.props.user;
        const recordName = this.props.recordName;
        const breadcrumbData = sAction.breadcrumb(recordName);
        const alertMessage = this.props.alertMessage;
        let menuButton = (
            <div className='burgerMenu' onClick={() => sAction.menuOpen()} />
        );
        menuButton = null;
        const isSuperUser = user.is_superUser !== null && !!user.is_superUser;

        const notificationData = this.props.not;

        let headerContentClass = 'headerContent';
        let develModeString = '';
        if (sAction.develMode === true) {
            headerContentClass += window.location.hostname !== 'localhost' ? ' testEnviroment' : ' testEnviromentLocal';
            develModeString = window.location.hostname !== 'localhost' ? sAction.translate('LBL_TEST_ENVIROMENT') : 'Localhost';
        }
        const headerLogoUrl = this.props.systemSettings.headerLogo ?
            sAction.param.files+ 'image/' + this.props.systemSettings.headerLogo : 'img/coripo-logo.png';

        return (
            <header>
                {alertMessage.get('show') === true && (
                    <div className='headerAlertPanel'>
                        {alertMessage.get('message')}
                        {alertMessage.get('buttonAction') != null && (
                            <a onClick={() => this.alertMessageClick()}>
                                {alertMessage.get('buttonLabel')}
                            </a>
                        )}
                    </div>
                )}

                <div className={headerContentClass}>
                    <div className='headerLeftContainer'>
                        <div className='headerLogoContainer'>
                            <a href='#home' className='headerLogoContent'>
                                <img className='headerLogo' src={headerLogoUrl} />
                            </a>

                            <a className='logo' href='#home'>
                                {this.props.systemSettings.systemName}
                            </a>
                        </div>
                        <Breadcrumb data={breadcrumbData} />
                    </div>
                    {sAction.develMode === true &&
          <div className='develModePanel'>
              {develModeString}
          </div>
                    }
                    {menuButton}
                    <div className='userMenu'>
                        <div className='headerGlobarSearch'>
                            <InputText
                                containerClassName='globalSearchInput'
                                type='text'
                                placeholder={sAction.translate('LBL_GLOBAL_SEARCH_PLACEHOLDER')}
                                myRef={this.searchInput}
                                onKeyUp={(ev) => this.searchKeyUp(ev)}
                            />
                            <TooltipWrapper label={'LBL_SEARCHED'}>
                                <div
                                    className='icon-search userHeaderButton'
                                    onClick={() => this.handleSearch()}
                                />
                            </TooltipWrapper>
                        </div>

                        <NotificationCenter data={notificationData} />
                        <RecentRecordsManager />
                        <TooltipWrapper label={'LBL_ADVANCED_SEARCH'}>
                            <div
                                className='icon-filter headerFiltering userHeaderButton'
                                onClick={() => sAction.openReportWindow()}
                            />
                        </TooltipWrapper>

                        <div className='userMenuContainer'>
                            <a
                                href={'#detail/Users/' + user.id}
                                className='userMenuContainerHeader'
                            >
                                <div className='icon-avatar profileIcon' />
                                <div className='userName'>{user.name}</div>
                            </a>
                            <div className='userMenuContent'>
                                <div className='userMenuContentProfile'>
                                    {user.profile_photo ?
                                        <img
                                            className='userMenuContentAvatar'
                                            src={`${sAction.param.files}image/${user.profile_photo}`}/> :
                                        <div className='userMenuContentAvatar icon-avatar'></div>}
                                    <div className='userMenuContentProfileBox'>
                                        <div>{user.name}</div>
                                        <div>{user.user_name}</div>
                                    </div>
                                    <a className='userMenuContentSettings' href={'#detail/Users/' + user.id}>
                                        <div className='userMenuContentSettings-icon iconfas-settings'></div>
                                    </a>
                                </div>
                                {(user.is_admin === '1' || isSuperUser) && (
                                    <a href={'#admin'}>

                                    </a>
                                )}
                                <div className='userMenuContainerActions'>
                                    {(user.is_admin === '1' || isSuperUser) && (
                                        <a className='userMenuContainerActions-line' href={'#admin'}>
                                            <span className='icon-admin '></span>
                                            <div>{sAction.translate('LBL_ADMINISTRATION')}</div>
                                        </a>
                                    )}
                                    {sAction.getStorage('debug') && (
                                        <React.Fragment>
                                            <a className='userMenuContainerActions-line' href={'#admin/custom/repair_quick'}>
                                                <span className='icon-wrench '></span>
                                                <div>{sAction.translate(
                                                    'LBL_QUICK_REPAIR_AND_REBUILD',
                                                    'Administration',
                                                )}</div>
                                            </a>
                                            <a className='userMenuContainerActions-line' href={'#admin/custom/studio'}>
                                                <span className='icon-admin '></span>
                                                <div>Studio</div>
                                            </a>
                                        </React.Fragment>
                                    )}
                                    <a className='userMenuContainerActions-line' href={'#list/acm_wiki'}>
                                        <span className='icon-support '></span>
                                        <div>{sAction.translate('LBL_PODPORA')}</div>
                                    </a>
                                    <div onClick={() => sAction.logOut()} className='userMenuContainerActions-line'>
                                        <span className='icon-logout '></span>
                                        <div>{sAction.translate('LBL_LOGOUT')}</div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </header>
        );
    }
}

Header.propTypes = {
    alertMessage: PropTypes.shape({
        get: PropTypes.func,
    }),
    not: PropTypes.any,
    recordName: PropTypes.any,
    systemSettings: PropTypes.shape({
        headerLogo: PropTypes.any,
        systemName: PropTypes.any,
    }),
    user: PropTypes.shape({
        id: PropTypes.any,
        is_admin: PropTypes.string,
        is_superUser: PropTypes.any,
        name: PropTypes.any,
        profile_photo: PropTypes.string,
        user_name: PropTypes.string,
    }),
};

export default Header;
