/**
 *
 * @param params
 */
export default function sendFileConfirm(params) {
    if (!this.requiredFieldsCheckMsg(params.prefix)) {
        return;
    }

    // popup, kde se zeptame na typ faktury
    const {module} = params;

    const paramData = {...params, filetype: 'pdf', save: true};

    if (module === 'Quotes') {
        this.getSavedPrintFile(paramData);
        return;
    }

    const data = {
    // Tisk faktury/objednávky
        header: this.translate('LBL_SEND_INV', module),
        buttons: [
            {label: this.translate('LBL_CANCEL'), callback: () => this.popupHide()},
            // Tisk faktury/objednávky
            {
                label: this.translate('LBL_SEND_INV', module),
                callback: () => {
                    this.getSavedPrintFile({...paramData, type: 'all'}); this.popupHide();
                },
            },
            // "Tisk souhrnné faktury/objednávky"
            {
                label: this.translate('LBL_SEND_SUMINV', module),
                callback: () => {
                    this.getSavedPrintFile({...paramData, type: 'summary'}); this.popupHide();
                },
            },
        ],
    };

    // Volba "Poslat souhrnou fakturu/objednávku" pošle fakturu bez řádků faktury/objednávky.
    // Volba "Poslat fakturu/objednávku" pošle fakturu včetně řádků faktury/objednávky.
    const message =
    this.translate('LBL_TSENDEXT_SUMMARY_INV', module) + '<br>' + this.translate('LBL_SENDTEXT_INV', module);

    this.popup(message, data);
}
