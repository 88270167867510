/**
 *
 * @param {*} paramData
 * @param {*} callback
 */
export default function searchAddressByGPS(paramData, callback) {
    this.rest.post('acmAddressByGPS', paramData, (data) => {
        callback(data);
    });
}
