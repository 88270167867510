/**
 *
 * @param {{}} paramData
 * @param {function} callback
 * @returns {{data: {}}}
 */
export default function searchAcmAddress(paramData, callback) {
    const query = paramData.city ? `${paramData.city ?? ''} ${paramData.street ?? ''}` : paramData.street ?? '';
    if (!query || query === ' ') {
        const emptyResponse = {data: {}};
        callback(emptyResponse);
        return emptyResponse;
    }

    this.rest.fetchMS('maps/v1/get-poi', 'GET', {query}).then((response) => {
        if (response.status) {
            callback(response.data);
            return response.data;
        }
        console.error('Get poi failed, response: ', response);
        callback([]);
        return response;
    });
}
