/* eslint-disable no-case-declarations */
import moment from 'moment';
import detailDefault from '../../detailDefault';

export default class detailMeetings extends detailDefault {
    load(sAction, data) {
        let prefixInv = data.prefix + '/customData/invitees';

        if (['calendar', 'home', ''].includes(data.view)) {
            prefixInv = data.prefix.replace('/detailView', '');
            prefixInv += '/invitees';
            // nastaveni minut do definice pole duration_hours k zobrazeni pri editaci
            const durationMinutes = sAction.dataGet(data.prefix + '/fields/duration_minutes/value');
            sAction.dataSet(data.prefix + '/fields/duration_hours/def/minutesValue', durationMinutes);
        }

        this.searchInvitees(sAction, data.prefix, prefixInv);
        sAction.dsClear();

        if (!sAction.dataGet(data.prefix + '/id')) {
            if (!['calendar', 'home', ''].includes(data.view)) {
                const now = new Date();
                const mins = now.getMinutes();
                const rounded = Math.round(mins / 30) * 30;
                // Dvojty zapor aby se nepricitalo - JS u pricitani stringu nefunguje
                const today = this.getFormatedDate(now.getTime() - (1000 * 60 * (mins - rounded)));
                // - 30 kvuli defaultni delce 30 minut. Zapor kvuli tomu, ze se odcita 2x - vyhnu se pricitani stringu
                const end = this.getFormatedDate(now.getTime() - (1000 * 60 * (mins - rounded - 30)));

                // Kontrola zda nejsou ulozene hodiny a minuty kvuli duplikaci.
                sAction.dsAdd('set', data.prefix + '/fields/date_start/value', today);
                sAction.dsAdd('set', data.prefix + '/changes/fields/date_start', today);
                sAction.dsAdd('set', data.prefix + '/fields/date_end/value', end);
                sAction.dsAdd('set', data.prefix + '/changes/fields/date_end', end);
            }

            const initHours = +sAction.dataGet(data.prefix + '/fields/duration_hours/value') || 0;
            const initMinutes = +sAction.dataGet(data.prefix + '/fields/duration_hours/def/minutesValue') || 30;

            sAction.dsAdd('set', data.prefix + '/fields/duration_hours/value', initHours);
            sAction.dsAdd('set', data.prefix + '/fields/duration_hours/def/minutesValue', initMinutes);
            sAction.dsAdd('set', data.prefix + '/changes/fields/duration_hours', initHours);
            sAction.dsAdd('set', data.prefix + '/changes/fields/duration_minutes', initMinutes);
        }

        this.locationLink(sAction, data.prefix);

        sAction.dsProcess();
        sAction.dsClear();

        if (sAction.dataGet(data.prefix + '/fields/all_day/value') === '1') {
            sAction.dsClear();
            sAction.dsAdd('set', data.prefix + '/fields/date_start/def/readonly', true);
            sAction.dsAdd('set', data.prefix + '/fields/date_end/def/readonly', true);
            sAction.dsAdd('set', data.prefix + '/fields/duration_hours/def/readonly', true);
            sAction.dsProcess();
        }

        this.checkAllDay(sAction, data);

        const parentAccount = {
            id: sAction.dataGet(data.prefix + '/fields/parent_name/def/id_value'),
            name: sAction.dataGet(data.prefix + '/fields/parent_name/value'),
            loaded: false,
        };
        this.setParentAccount(sAction, data.prefix, parentAccount);
        this.reminderListSelectOffice365(sAction, data);
        sAction.dsProcess();
    }

    update(sAction, data) {
        // massUpdate = return, aby to nepadalo kvuli chybejicim fieldum
        if (data.prefix === 'rightPanel/data' && sAction.dataGet('rightPanel/content') === 'massUpdateView') {
            return;
        }

        let start = sAction.dataGet(data.prefix + '/fields/date_start/value');
        switch (data.field) {
            case 'parent_id':
                // console.log("UPDATE", data.prefix);
                let prefixInv = data.prefix + '/customData/invitees';
                if (data.view === 'calendar' || data.view === 'home') {
                    prefixInv = data.prefix.replace('/detailView', '').replace('/meetingView', '');
                    prefixInv += '/invitees';
                } else if (data.view !== 'detail') {
                    return;
                }
                this.searchInvitees(sAction, data.prefix, prefixInv);
                this.setParentAccount(sAction, data.prefix, data.value);
                // checkni prez store(spesl funkce)
                break;
            case 'date_end':
                start = new Date(start);
                let end = new Date(data.value);
                if (!this.checkValidDate(start, end, data, sAction)) {
                    end = new Date(start.getTime());
                }
                const diff = Math.abs(end - start);
                const minsCount = (Math.floor(diff / (1000 * 60))) % 60;
                const hoursCount = Math.floor(diff / (1000 * 60 * 60));
                sAction.dsClear();
                sAction.dsAdd('set', data.prefix + '/fields/duration_hours/value', hoursCount);
                sAction.dsAdd('set', data.prefix + '/fields/duration_hours/def/minutesValue', minsCount);
                sAction.dsAdd('set', data.prefix + '/changes/fields/duration_hours', hoursCount);
                sAction.dsAdd('set', data.prefix + '/changes/fields/duration_minutes', minsCount);
                sAction.dsProcess();
                this.checkSwitchToAllDay(sAction, data);
                break;
            case 'duration_hours':
            case 'date_start':
                const hours = sAction.dataGet(data.prefix + '/fields/duration_hours/value');
                const mins = sAction.dataGet(data.prefix + '/fields/duration_hours/def/minutesValue');

                start = new Date(start);
                const endDate = this.getFormatedDate(start.getTime() + (1000 * 60 * ((mins * 1) + (hours * 60))));

                sAction.dsClear();
                sAction.dsAdd('set', data.prefix + '/fields/date_end/value', endDate);
                sAction.dsAdd('set', data.prefix + '/changes/fields/date_end', endDate);
                sAction.dsProcess();
                this.checkSwitchToAllDay(sAction, data);
                break;
            case 'all_day':
                this.checkAllDay(sAction, data);
                if (data.value === '1') {
                    const start = sAction.dataGet(data.prefix + '/fields/date_start/value');
                    const pomStart = moment(new Date(start));
                    let end = sAction.dataGet(data.prefix + '/fields/date_end/value');
                    if (!end) {
                        end = start;
                    }
                    const pomEnd = moment(new Date(end));
                    if (pomStart.format('YYYY-MM-DD') === pomEnd.format('YYYY-MM-DD')) {
                        pomEnd.add(1, 'days');
                    }
                    pomStart.set({hour: 0, minute: 0, second: 0});
                    pomEnd.set({hour: 0, minute: 0, second: 0});

                    sAction.dsClear();
                    const strEnd = pomEnd.format('YYYY-MM-DD HH:mm:ss');
                    const strStart = pomStart.format('YYYY-MM-DD HH:mm:ss');

                    sAction.dsAdd('set', data.prefix + '/fields/date_start/value', strStart);
                    sAction.dsAdd('set', data.prefix + '/fields/date_start/def/readonly', true);
                    sAction.dsAdd('set', data.prefix + '/changes/fields/date_start', strStart);

                    sAction.dsAdd('set', data.prefix + '/fields/date_end/value', strEnd);
                    sAction.dsAdd('set', data.prefix + '/fields/date_end/def/readonly', true);
                    sAction.dsAdd('set', data.prefix + '/changes/fields/date_end', strEnd);

                    const durationHours = pomEnd.diff(pomStart, 'hours');
                    sAction.dsAdd('set', data.prefix + '/fields/duration_hours/value', durationHours);
                    sAction.dsAdd('set', data.prefix + '/fields/duration_hours/def/minutesValue', 0);
                    sAction.dsAdd('set', data.prefix + '/changes/fields/duration_hours', durationHours);
                    sAction.dsAdd('set', data.prefix + '/changes/fields/duration_minutes', 0);
                    sAction.dsAdd('set', data.prefix + '/fields/duration_hours/def/readonly', true);

                    sAction.dsProcess();
                } else {
                    sAction.dsClear();
                    sAction.dsAdd('set', data.prefix + '/fields/date_start/def/readonly', false);
                    sAction.dsAdd('set', data.prefix + '/fields/date_end/def/readonly', false);
                    sAction.dsAdd('set', data.prefix + '/fields/duration_hours/def/readonly', false);
                    sAction.dsProcess();
                }
                break;
        }

        this.locationLink(sAction, data.prefix);
    }

    /**
     *
     * @param {class} sAction
     * @param {any} data
     */
    checkAllDay(sAction, data) {
        if (parseInt(sAction.dataGet(data.prefix + '/fields/all_day/value')) === 1) {
            let valDateStart = sAction.dataGet(data.prefix + '/fields/date_start/value');
            let valDateEnd = sAction.dataGet(data.prefix + '/fields/date_end/value');

            if (valDateStart) {
                valDateStart = moment(new Date(valDateStart)).format('YYYY-MM-DD');
            }
            if (valDateEnd) {
                valDateEnd = moment(new Date(valDateEnd)).format('YYYY-MM-DD');
            }

            sAction.dsClear();
            sAction.dsAdd('set', data.prefix + '/fields/date_start/value', valDateStart);
            sAction.dsAdd('set', data.prefix + '/fields/date_start/def/type', 'date');
            sAction.dsAdd('set', data.prefix + '/fields/date_start/actEdit', false);
            sAction.dsAdd('set', data.prefix + '/fields/date_end/value', valDateEnd);
            sAction.dsAdd('set', data.prefix + '/fields/date_end/def/type', 'date');
            sAction.dsAdd('set', data.prefix + '/fields/date_end/actEdit', false);
            sAction.dsProcess();
        } else {
            let valDateStart = sAction.dataGet(data.prefix + '/fields/date_start/value');
            let valDateEnd = sAction.dataGet(data.prefix + '/fields/date_end/value');

            if (valDateStart) {
                valDateStart = moment(new Date(valDateStart)).format('YYYY-MM-DD HH:mm:ss');
            }
            if (valDateEnd) {
                valDateEnd = moment(new Date(valDateEnd)).format('YYYY-MM-DD HH:mm:ss');
            }

            sAction.dsClear();
            sAction.dsAdd('set', data.prefix + '/fields/date_start/value', valDateStart);
            sAction.dsAdd('set', data.prefix + '/fields/date_end/value', valDateEnd);
            sAction.dsAdd('set', data.prefix + '/fields/date_end/date_start', false);
            sAction.dsAdd('set', data.prefix + '/fields/date_start/def/type', 'datetimecombo');
            sAction.dsAdd('set', data.prefix + '/fields/date_end/def/type', 'datetimecombo');
            sAction.dsAdd('set', data.prefix + '/fields/date_end/actEdit', false);
            sAction.dsProcess();
        }
    }

    /**
     *
     * @param {class} sAction
     * @param {object} data
     */
    checkSwitchToAllDay(sAction, data) {
        // when date diff is more than one day, switch to all day format
        const allDay = sAction.dataGet(data.prefix + '/fields/all_day/value');
        let startDate = sAction.dataGet(data.prefix + '/fields/date_start/value');
        let endDate = sAction.dataGet(data.prefix + '/fields/date_end/value');

        if ((parseInt(allDay) === 0 || allDay === null) && startDate !== null && endDate !== null) {
            startDate = moment(startDate).format('YYYY-MM-DD');
            endDate = moment(endDate).format('YYYY-MM-DD');

            // eslint-disable-next-line eqeqeq
            if (startDate < endDate && startDate !== 'Invalid date' && endDate !== 'Invalid date') {
                // popup switch to allday format?
                sAction.confirm(sAction.translate('LBL_SWITCH_TO_ALLDAY', 'Meetings'), () => {
                    sAction.popupHide();
                    sAction.dsClear();
                    let valDateStart = sAction.dataGet(data.prefix + '/fields/date_start/value');
                    let valDateEnd = sAction.dataGet(data.prefix + '/fields/date_end/value');
                    if (valDateStart) {
                        valDateStart = moment(new Date(valDateStart)).format('YYYY-MM-DD');
                    }
                    if (valDateEnd) {
                        valDateEnd = moment(new Date(valDateEnd)).format('YYYY-MM-DD');
                    }

                    sAction.dsAdd('set', data.prefix + '/fields/all_day/value', '1');
                    sAction.dsAdd('set', data.prefix + '/changes/fields/all_day', '1');
                    sAction.dsAdd('set', data.prefix + '/fields/date_start/value', valDateStart);
                    sAction.dsAdd('set', data.prefix + '/fields/date_start/def/type', 'date');
                    sAction.dsAdd('set', data.prefix + '/fields/date_start/actEdit', false);
                    sAction.dsAdd('set', data.prefix + '/fields/date_end/value', valDateEnd);
                    sAction.dsAdd('set', data.prefix + '/fields/date_end/def/type', 'date');
                    sAction.dsAdd('set', data.prefix + '/fields/date_end/actEdit', false);
                    sAction.dsProcess();
                });
            }
        }
    }

    /**
     *
     * @param {*} sAction
     * @param {*} prefix
     */
    locationLink(sAction, prefix) {
        const location = sAction.dataGet(prefix + '/fields/location');
        const value = location?.value;
        if (value != null) {
            if (value.startsWith('http') || value.startsWith('www')) {
                sAction.dataSet(prefix + '/fields/location/def/type', 'url');
            }
        }
    }
    /**
     *
     * @param {*} sAction
     * @param {*} prefix
     * @param {*} prefixInv
     */
    searchInvitees(sAction, prefix, prefixInv) {
    // console.log("PARENT", prefix);
        const parent = sAction.dataGet(prefix + '/fields/parent_name');
        if (parent && parent.def && parent.def.get('parent_type_value') === 'Accounts' && parent.def.get('id_value')) {
            sAction.searchInvitees({parent: parent.toJS()}, prefixInv);
        }
    }

    /**
     *
     * @param {*} timeStamp
     * @returns {string}
     */
    getFormatedDate(timeStamp) {
        const date = new Date(timeStamp);
        const year = date.getFullYear();
        let month = date.getMonth() + 1;
        if (month < 10) {
            month = '0' + month;
        }
        let day = date.getDate();
        let hours = date.getHours();
        let minutes = date.getMinutes();
        if (day < 10) {
            day = '0' + day;
        }
        if (hours < 10) {
            hours = '0' + hours;
        }
        if (minutes < 10) {
            minutes = '0' + minutes;
        }

        return (year + '-' + month + '-' + day + ' ' + hours + ':' + minutes + ':00');
    }

    /**
     *
     * @param {*} start
     * @param {*} end
     * @param {*} data
     * @param {*} sAction
     * @returns {boolean}
     */
    checkValidDate(start, end, data, sAction) {
        const startStamp = start.getTime();
        const endStamp = end.getTime();

        if (endStamp < startStamp) {
            sAction.dsClear();
            const endDate = this.getFormatedDate(startStamp);
            sAction.dsAdd('set', data.prefix + '/fields/date_end/value', endDate);
            sAction.dsAdd('set', data.prefix + '/changes/fields/date_end', endDate);
            sAction.dsProcess();
            sAction.error(sAction.translate('LBL_END_DATE_IS_HIGHER_THAN_START'));

            return false;
        } else {
            return true;
        }
    }

    /**
     *
     * @param {*} sAction
     * @param {*} prefix
     * @param {*} parent
     */
    setParentAccount(sAction, prefix, parent) {
        let customData = sAction.dataGet(prefix + '/customData');
        const parentAccount = parent ? parent : {};
        parentAccount.loaded = false;

        if (!customData) {
            customData = {
                parentAccount: parentAccount,
            };

            sAction.dsClear();
            sAction.dsAdd('set', prefix + '/customData', customData);
            sAction.dsProcess();
        } else {
            sAction.dsClear();
            sAction.dsAdd('set', prefix + '/customData/parentAccount', parentAccount);
            sAction.dsProcess();
        }
    }

    /**
     * Change correctly options for select, same as outlook meeting
     *
     * @param {*} sAction
     * @param {{}} data
     */
    reminderListSelectOffice365(sAction, data) {
        const office365Active = sAction.dataGet('conf/plugins/office365');
        if (!office365Active) {
            return;
        }
        const allDay = sAction.dataGet(`${data.prefix}/fields/all_day/value`);
        if (+allDay) {
            sAction.dsAdd('set', `${data.prefix}/fields/reminder_time/def/options`, 'all_day_msgraph_reminder_time_options');
        } else {
            sAction.dsAdd('set', `${data.prefix}/fields/reminder_time/def/options`, 'reminder_time_options');
        }
    }
}
