import PureComponent from '../pure';
import sAction from 'sAction';
import PropTypes from 'prop-types';

export default class FormField extends PureComponent {
    componentDidMount() {}

    /**
     * @param {boolean} shift
     * @return {{
     *     name: string,
     *     prefix: *,
     *     index: *,
     *     rowWay: *,
     *     type: *,
     *     shift: boolean,
     * }}
     */
    getTabData(shift = false) {
        if (shift === false) {
            return {
                name: this.props.name,
                prefix: this.props.prefix,
                index: this.props.index,
                rowWay: this.props.rowWay,
                type: this.props.def.get('type'),
                shift: false,
            };
        } else {
            return {
                name: this.props.name,
                prefix: this.props.prefix,
                index: this.props.index,
                rowWay: this.props.rowWay,
                type: this.props.def.get('type'),
                shift: true,
            };
        }
    }

    /**
     * @param {KeyboardEvent} e
     * @param {string|null} type
     * @param {boolean} save
     */
    onKeyDown(e, type = null, save = true) {
        let data = null;
        if (e.key === 'Tab') {
            data = this.getTabData(e.shiftKey);
        }
        if (data != null && this.props.prefix) {
            e.preventDefault();
            if (save === true) {
                if (this.check()) {
                    this.save(e);
                } else {
                    data['error'] = true;
                }
            }
            sAction.detailTabNext(data, save);
        }
    }

    /**
     *@return {{
     *     name: string,
     *     value: *,
     *}}
     */
    getValue() {
        // eslint-disable-next-line react/no-string-refs
        const value = this.refs.input.value;

        return {name: this.props.name, value: value};
    }
}
FormField.propTypes = {
    name: PropTypes.string.isRequired,
    prefix: PropTypes.string.isRequired,
    index: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
    ]),
    rowWay: PropTypes.string,
    def: PropTypes.object,
};
