/**
 *
 * @param {*} filter
 * @param {*} where
 * @param {*} _callback
 */
export default function searchPlaces(filter, where, _callback) {
    this.rest.post('findAddress/' + where, {filter: filter}, (data) => {
        _callback(data);
    });
}
