/**
 * @param {object} sendData
 * @param {string} prefix
 * @this sAction
 * @TODO rest.post => rest.fetchData
 * @render src/components/popup/detailApi/AresChooseAccountPopup.js
 */
export default function ares(sendData, prefix = 'view') {
    this.load();

    sendData.ares_zastupci = !!this.dataGet(prefix + '/fields/ares_zastupci');
    const url = sendData.ico ? 'ares' : 'ares2';

    this.rest.post('detailapi/' + url, sendData, (data) => {
        delete data['status']
        delete data['message']
        delete data['errorMessage']
        this.unLoad();

        const fields = ['billing', 'primary', 'Contactsprimary'];

        fields.forEach((field) => {
            data.forEachObject((v, k) => {
                if (k.charAt(0) === '_') {
                    data[field + k] = v;
                    // Oprava aresu aby nemazal adresy a prepsalo se vse
                    if (k === fields.length - 1) {
                        delete data[k];
                    }
                }
            });
        });

        if (typeof data !== 'object') {
            this.error(this.translate('LBL_ARES_NO_DATA', 'Accounts'));

            return;
        }

        if (!data.num) {
            this.error(
                this.translate('LBL_ARES_NOT_FOUND', 'Accounts'),
            );

            return;
        }

        if (data.num && data.num > 1) {
            delete data.num;
            const params = {
                'header': this.translate('LBL_VYBER', 'acm_reports'),
                'accounts': data,
                prefix,
            };
            this.popup('aresChooseAccountPopup', params );

            return;
        }

        if (!data.sic_code) {
            data.sic_code = null;
        }

        this.updateFields(data, prefix);

        if (data.ticker_symbol) {
            this.checkICO(data.ticker_symbol);
        }
    });
}
